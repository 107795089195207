import React, { useRef, useState, useEffect } from "react";
import SchoolsCard from "./_components/SchoolsCard";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { IoIosSearch } from "react-icons/io";
import { authAxios } from "../../config/axios-config";
import { Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import routes from "../../routes";
import breakingLimitsLogo from "../../assets/pictures/breaking limits logo.jpg";

export default function BreakingLimits() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEn, setIsLoadingEn] = useState(false);
  const [isLoadingAr, setIsLoadingAr] = useState(false);
  const [materialen, setMaterialEn] = useState("");
  const [materialar, setMaterialAr] = useState("");
  const [errorMaterial, setErrorMaterial] = useState("");
  const [schoolsInterested, setSchoolsInterested] = useState([]);
  const [searchName, setSearchName] = useState("");
  const history = useHistory();

  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const schoolId = localStorage.getItem("id");
  const getMaterial = async (language) => {
    try {
      if (language === "en") setIsLoadingEn(true);
      if (language === "ar") setIsLoadingAr(true);

      const response = await authAxios.get(`/schools/${schoolId}/${language}`);
      const downloadLink = response?.data?.data?.downloadLink;
      if (language === "en") setMaterialEn(downloadLink);
      if (language === "ar") setMaterialAr(downloadLink);

      const link = document.createElement("a");
      link.href = downloadLink;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (language === "en") setIsLoadingEn(false);
      if (language === "ar") setIsLoadingAr(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.errors?.[0]?.message;

      setErrorMaterial(errorMessage);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: selectedContent[localizationKeys.go],
        cancelButtonText: selectedContent[localizationKeys.cancel],
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(routes.settings);
        }
      });
    } finally {
      if (language === "en") setIsLoadingEn(false);
      if (language === "ar") setIsLoadingAr(false);
    }
  };

  const viewInterestedSchools = async () => {
    try {
      setIsLoading(true);
      const response = searchName
        ? await authAxios.get(`/schools/downloads?search=${searchName}`)
        : await authAxios.get(`/schools/downloads`);
      setSchoolsInterested(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    viewInterestedSchools();
  }, [searchName, materialen, materialar]);

  return (
    <section className="mx-5">
      <div className="flex flex-col flex-wrap justify-between gap-4 rounded-lg px-10 py-3 font-medium lg:flex-row lg:items-center lg:gap-0">
        <caption className="w-1/3 rounded-lg border-2 border-gray-300 p-6 text-start">
          <h5 className="text-primary-900 my-2">
            {selectedContent[localizationKeys.toSubscribtion]}
          </h5>
          <p>{selectedContent[localizationKeys.subscribtionRoleOne]}</p>
          <p>{selectedContent[localizationKeys.subscribtionRoleTwo]}</p>
          <p>{selectedContent[localizationKeys.subscribtionRoleThree]}</p>
          <p>{selectedContent[localizationKeys.subscribtionRoleFour]}</p>
          <p>{selectedContent[localizationKeys.subscribtionRoleFive]}</p>
          <p>{selectedContent[localizationKeys.subscribtionRoleSix]}</p>
        </caption>
        <div className="flex items-center justify-center lg:w-1/3">
          <figure className="flex h-[200px] w-[200px] items-center">
            <img className="w-full" src={breakingLimitsLogo} alt="logo" />
          </figure>
        </div>
        <div className="rounded-lg border-2 border-gray-300 p-6 lg:w-1/3">
          <b className="mb-4 block text-primary-900">
            {selectedContent[localizationKeys.BreakingLimitsAlert]}
          </b>
          <div className="flex items-center justify-center gap-4">
            <div
              onClick={() => getMaterial("en")}
              className="relative cursor-pointer rounded-lg bg-gray-200 px-8 py-2 hover:text-primary-900"
              disabled={isLoadingEn}
            >
              {selectedContent[localizationKeys.DownloadMaterialByEnglish]}{" "}
              <span className="text-primary-900">(EN)</span>
              <span className="absolute top-1/2 -translate-y-1/2 px-4">
                <Loader
                  className={`${isLoadingEn ? "visible" : "hidden"}`}
                  size="tiny"
                />
              </span>
            </div>

            <div
              onClick={() => getMaterial("ar")}
              className="relative cursor-pointer rounded-lg bg-gray-200 px-8 py-2 hover:text-primary-900"
              disabled={isLoadingEn}
            >
              {selectedContent[localizationKeys.DownloadMaterialByArabic]}{" "}
              <span className="text-primary-900">(AR)</span>
              <span className="absolute top-1/2 -translate-y-1/2 px-4">
                <Loader
                  className={`${isLoadingAr ? "visible" : "hidden"}`}
                  size="tiny"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between px-4">
        <h2 className="my-8 px-4 text-2xl font-bold text-primary-900">
          {selectedContent[localizationKeys.allSchoolsProfiles]} :
        </h2>
        <div className="relative rounded-md md:mb-4 lg:w-1/3">
          <IoIosSearch className="absolute top-1/2 left-3 -translate-y-1/2 font-bold text-black" />
          <input
            onChange={(e) => setSearchName(e.target.value)}
            className="w-full rounded-lg border-2 border-gray-300 py-2 px-4 pl-10 caret-primary-900 focus:outline-none"
            type="search"
          />
        </div>
      </div>
      <div className="flex flex-wrap">
        {isLoading ? (
          <Loader size="medium" active={true} />
        ) : (
          <SchoolsCard schoolsInterested={schoolsInterested} />
        )}
      </div>
    </section>
  );
}
