import localizationKeys from "./localization-keys";

const en = {
  [localizationKeys.ejad]: "Ejad",
  [localizationKeys.login]: "Login to your account",
  [localizationKeys.loginButton]: "Login",
  [localizationKeys.email]: "Email",
  [localizationKeys.password]: "Password",
  [localizationKeys.registration]: "Sign up",
  [localizationKeys.forgotPassword]: "Forgot your password?",
  [localizationKeys.dontHaveAnAccont]: "Don't have an account?",
  [localizationKeys.required]: "Required",
  [localizationKeys.emailError]: "Must be an email",
  [localizationKeys.name_ar]: "School name in arabic",
  [localizationKeys.name_en]: "School name in english",
  [localizationKeys.phone]: "Phone number",
  [localizationKeys.confirmPassword]: "Confirm password",
  [localizationKeys.alreadyHaveAnAccount]: "Already have an account?",
  [localizationKeys.signupEmailVerificationMessage]:
    "A verification link has been sent to your email",
  [localizationKeys.phoneMustBeNumber]: "Phone number must be numbers only",
  [localizationKeys.phoneMustBeNineCharchters]:
    "Phone number must be 9 numbers",
  [localizationKeys.recoverPassword]: "Reset password",
  [localizationKeys.recover]: "reset",
  [localizationKeys.verifyEmailAddressMessage]: "Your email is verified",
  [localizationKeys.verifyEmailAddress]: "Verify email address",
  [localizationKeys.resetPasswordMessage]: "Password changed successfully",
  [localizationKeys.changePassword]: "change password",
  [localizationKeys.checkEmailResetLink]:
    "After submiting your email, a link is sent to enter your new password",
  [localizationKeys.welcomeMessage]: "Welcome to Ejad",
  [localizationKeys.passwordError]: "passwordError",
  [localizationKeys.confirmPasswordMssg]: "confirmPasswordMssg",
  [localizationKeys.completeProfileTitle]: "Complete profile",
  [localizationKeys.thisDataIsRequired]:
    "This data is required to enjoy our website.",
  [localizationKeys.city]: "City",
  [localizationKeys.educationalOffice]: "Educational office",
  [localizationKeys.educationalLevels]: "Educational levels",
  [localizationKeys.schoolType]: "School type",
  [localizationKeys.registrationNumber]: "Registration number",
  [localizationKeys.address]: "Address",
  [localizationKeys.studentType]: "Student type",
  [localizationKeys.uploadPhotos]: "Upload photos",
  [localizationKeys.male]: "male",
  [localizationKeys.female]: "female",
  [localizationKeys.edit]: "edit",
  [localizationKeys.brief]: "about school",
  [localizationKeys.addLogo]: "Add logo",
  [localizationKeys.addImage]: "Add image",
  [localizationKeys.logout]: "Logout",
  [localizationKeys.cancelButton]: "cancel",
  [localizationKeys.confirmButton]: "ok",
  [localizationKeys.logoutMessage]: "Are you sure you want to Logout?",
  [localizationKeys.school]: "school",
  [localizationKeys.vacancies]: "Vacancies",
  [localizationKeys.opened]: "Opened",
  [localizationKeys.closed]: "Closed",
  [localizationKeys.pleaseCompleteYourProfile]:
    "Please complete your profile first",
  [localizationKeys.pleaseCompleteYourProfileText]:
    "To continue using Ejad and enjoy its services, you must complete your profile first.",
  [localizationKeys.goToProfile]: "Go to your profile",
  [localizationKeys.save]: "save",
  [localizationKeys.delete]: "delete",
  [localizationKeys.updatedSuccessfully]: "updated successfully",
  [localizationKeys.create]: "Create",
  [localizationKeys.gender]: "Gender",
  [localizationKeys.educationalOfficeName]: "Educational office name",
  [localizationKeys.addEducationalOffice]: "Add new educational office",
  [localizationKeys.createdSuccessfully]: "Added successfully",
  [localizationKeys.cancel]: "cancel",
  [localizationKeys.mainPage]: "Main",
  [localizationKeys.YouHave]: " You have",
  [localizationKeys.freePublicationsLeft]:
    " free publications left, the cost of the post will be {X} saudi riyals",
  [localizationKeys.addNewVacancy]: "Add a new job post cost",
  [localizationKeys.pastPublications]: "Previous publications",
  [localizationKeys.thereAreNoPublications]: "There are no jobs posted yet.",
  [localizationKeys.drafted]: "drafted",
  [localizationKeys.active]: "active",
  [localizationKeys.archived]: "archive",
  [localizationKeys.eduLevel]: "Educational level",
  [localizationKeys.track]: "Educational track",
  [localizationKeys.typeOfInstructors]: "Type of instructors",
  [localizationKeys.salary]: "Salary",
  [localizationKeys.RS]: "R.S",
  [localizationKeys.determinedAtTheInterview]: "Determined at the interview",
  [localizationKeys.more]: "see more",
  [localizationKeys.close]: "close",
  [localizationKeys.vacancyDetails]: "vacancyDetails",
  [localizationKeys.vacancy]: "vacancy",
  [localizationKeys.subject]: "subject",
  [localizationKeys.educationalLevel]: "Educational level",
  [localizationKeys.publishedDate]: "Published date",
  [localizationKeys.numberOfApplied]: "Number of applied",
  [localizationKeys.applicant]: "Applicant",
  [localizationKeys.jobDescription]: "Job description",
  [localizationKeys.deleteVacancy]:
    "Are you sure you want to delete this vacancy?",
  [localizationKeys.yesDeleteVacancy]: "delete vacancy",
  [localizationKeys.afterDeletionMessage]: "deletedSuccessfully",
  [localizationKeys.no]: "No",
  [localizationKeys.deleteVacancyNote]:
    "If you deleted this job post, all data related to it will be permanently deleted",
  [localizationKeys.closeVacancyTitle]:
    "Are you sure you want to close this job posting?",
  [localizationKeys.closeVacancyNote]:
    "If you close a job post, it cannot be available again",
  [localizationKeys.vacancyIsClosedSuccessfully]:
    "Vacancy is closed successfully",
  [localizationKeys.yesCloseVacancy]: "close vacancy",
  [localizationKeys.addNewJobPosting]: "Add a new job posting",
  [localizationKeys.stepOne]: "Step One",
  [localizationKeys.stepTwo]: "Step Two",
  [localizationKeys.jobName]: "Job Name",
  [localizationKeys.description]: "Description",
  [localizationKeys.workingPlace]: "Working place",
  [localizationKeys.workingTime]: "Working time",
  [localizationKeys.saveDraft]: "Save draft",
  [localizationKeys.publish]: "Publish",
  [localizationKeys.addNewJobPosting]: "Edit job posting",
  [localizationKeys.descriptionPlaceHolder]: "Write more details about the job",
  [localizationKeys.male2]: "male",
  [localizationKeys.female2]: "female",
  [localizationKeys.next]: "Next",
  [localizationKeys.noResultMessage]: "Not Found",
  [localizationKeys.fileName]: "File name",
  [localizationKeys.employmentApplications]: "Employment apps",
  [localizationKeys.jobs]: "Jobs",
  [localizationKeys.drafted2]: "Drafts",
  [localizationKeys.active2]: "Available",
  [localizationKeys.archived2]: "Closed",
  [localizationKeys.paying]: "Payment",
  [localizationKeys.settings]: "Settings",
  [localizationKeys.language]: "Language",
  [localizationKeys.arabic]: "Arabic",
  [localizationKeys.english]: "English",
  [localizationKeys.applied]: "applicants",
  [localizationKeys.applicantName]: "applicantName",
  [localizationKeys.cv]: "CV",
  [localizationKeys.status]: "Status",
  [localizationKeys.actions]: "Actions",
  [localizationKeys.all]: "all",
  [localizationKeys.view]: "view",
  [localizationKeys.isViewed]: "viewed",
  [localizationKeys.isNotViewed]: "not viewed",
  [localizationKeys.download]: "download",
  [localizationKeys.underReview]: "Under review",
  [localizationKeys.approved]: "Approved",
  [localizationKeys.notApproved]: "Not approved",
  [localizationKeys.approve]: "Approve",
  [localizationKeys.decline]: "Decline",
  [localizationKeys.approvingTitle]:
    "Are you sure you want to approve instructor",
  [localizationKeys.approvingBody]:
    "In case of approving, you can not go back to this procedure again",
  [localizationKeys.rejectingTitle]:
    "Are you sure you want to reject instructor",
  [localizationKeys.rejectingBody]:
    "In case of rejecting, you can not go back to this procedure again",
  [localizationKeys.approvedSuccessfully]: "approved successfully",
  [localizationKeys.approvedSuccessfully]: "rejected successfully",
  [localizationKeys.freeTrial]: "Free trial",
  [localizationKeys.noEmploymentApplications]:
    "There are no employment applications",
  [localizationKeys.profileIsCompletedSuccessfully]:
    "Profile is completed successfully",
  [localizationKeys.editLogo]: "Edit logo",
  [localizationKeys.editImage]: "Edit image",
  [localizationKeys.publishedSuccessfully]: "Published successfully",
  [localizationKeys.publishVacancyTitle]:
    "Are you sure you want to publish this vacancy?",
  // [localizationKeys.loading]: "Payment is done",
  [localizationKeys.loading]: "Loading",
  [localizationKeys.failedMessage]:
    "We are sorry that the payment was not completed successfully for the following reasons",
  [localizationKeys.returnHome]: "Return home",
  [localizationKeys.errorCode]: "Code no.",
  [localizationKeys.okUnderstand]: "ok, understand",
  [localizationKeys.MF001]:
    "3DS authentication failed, possible reasons (user inserted a wrong password, cardholder/card issuer are not enrolled with 3DS, or the issuer bank has technical issue).",
  [localizationKeys.MF002]:
    "The issuer bank has declined the transaction, possible reasons (invalid card details, insufficient funds, denied by risk, the card is expired/held, or card is not enabled for online purchase).",
  [localizationKeys.MF003]:
    "The transaction has been blocked from the gateway, possible reasons (unsupported card BIN, fraud detection, or security blocking rules).",
  [localizationKeys.MF004]: "Insufficient funds",
  [localizationKeys.MF005]: "Session timeout",
  [localizationKeys.MF006]: "Transaction canceled",
  [localizationKeys.MF007]: "The card is expired.",
  [localizationKeys.MF008]: "The card issuer doesn't respond.",
  [localizationKeys.MF009]: "Denied by Risk",
  [localizationKeys.MF010]: "Wrong Security Code",
  [localizationKeys.MF020]: "Unspecified Failure",
  [localizationKeys.vacancyPost]: "Vacancy post",
  [localizationKeys.dateAndTime]: "Date & Time",
  [localizationKeys.price]: "Price",
  [localizationKeys.noSubscribtionsFound]: "There are no previous transactions",
  [localizationKeys.deletedAccount]: "Deleted account",
  [localizationKeys.noJobApplicationsInThisJob]:
    "There are no job applications inside this job.",
  [localizationKeys.resetPassword]: "Reset password",
  [localizationKeys.submit]: "Submit",
  [localizationKeys.addNewEducationalOffice]: "Add new educational office",
  [localizationKeys.agreeToAllTermsAndConditions]:
    "I agree to all terms and conditions",
  [localizationKeys.send]: "send",
  [localizationKeys.forHelp]: "for help",
  [localizationKeys.followUsOn]: "follow us",
  [localizationKeys.callUs]: "Call us",
  [localizationKeys.userGuide]: "User guide",
  [localizationKeys.footer]:
    "When you use this website, You agree on our terms of service, privacy policy & copyrights, all rights reserved for Ejad, KSA ",
  [localizationKeys.aboutUs1]:
    "Ejad is an intermediary between schools that want to hire teachers, and teachers who want to get those jobs.",
  [localizationKeys.aboutUs2]:
    "You can list all the jobs available in your school through the Ejad website.",
  [localizationKeys.noInternet]: "No Internet connection",
  [localizationKeys.services]: "Services",
  [localizationKeys.aboutUs]: "About us",
  [localizationKeys.contactUs]: "Contact us",
  [localizationKeys.ourServices]: "Our services",
  [localizationKeys.ourServicesBody]:
    "Ejad provides many services to give you the best experience in hiring distinguished teachers and obtaining the desired results",
  [localizationKeys.service1Title]: "Contact the applicants",
  [localizationKeys.service1Body]:
    "You can contact the applicants directly and schedule personal interviews",
  [localizationKeys.service2Title]: "Post available jobs",
  [localizationKeys.service2Body]:
    "You can publish an unlimited number of jobs available in your school with an explanation of all the requirements to obtain these jobs.",
  [localizationKeys.service3Title]: "Receive offers of employment",
  [localizationKeys.service3Body]:
    "You will receive job applications from the most skilled Saudi teachers with their CVs.",
  [localizationKeys.service4Title]: "Accept/reject requests",
  [localizationKeys.service4Body]:
    "You can accept or reject job applications through the Ejad website to hire the best teacher.",
  [localizationKeys.mockupTitle]: "Ejad app for teachers",
  [localizationKeys.mockupBody]:
    "Ejad is an intermediary between schools that want to hire teachers, and teachers who want to get those jobs.",
  [localizationKeys.mockupBody2]:
    "Download the application and get your job now",
  [localizationKeys.getItFrom]: "Get it on",
  [localizationKeys.downloadFrom]: "Download on the",
  [localizationKeys.aboutEjad]: "About Ejad",
  [localizationKeys.aboutEjadBody]:
    "Ejad is a digital platform to facilitate the recruitment process and create a link between schools and teachers in the KSA, where schools can post their open vacancies in all tracks and academic levels, and the teachers can easily apply within one click on the available jobs in their specialty",
  [localizationKeys.contactUsBody]:
    "If you have any suggestions or inquiries, feel free to contact us now",
  [localizationKeys.writeHere]: "Write here...",
  [localizationKeys.fullName]: "Full name",
  [localizationKeys.landingPageTitle]:
    "Hire the best teachers in Saudi Arabia now",
  [localizationKeys.landingPageBody]:
    "Through the Ejad website, you can post all the jobs available in your school in all educational levels and tracks, and then obtain applications for these jobs from the best teachers in the Kingdom of Saudi Arabia",
  [localizationKeys.sendSuccessfully]: "Submitted successfuly",
  [localizationKeys.emailIsNotVerified]: "Your account is not verified",
  [localizationKeys.verifyEmailThroughThisLink]:
    "verify your email through this Link",
  [localizationKeys.linkIsSentSuccessfully]: "link is sent successfully",
  [localizationKeys.emailVerification]: "Verifying your account",
  [localizationKeys.emailVerificationText]:
    "to continue verifying your account, please check the link sent to your email",
  [localizationKeys.privacyPolicyTitle]: "Privacy Policy",
  [localizationKeys.privacyPolicyBody]:
    "At ejad.app, accessible from https://ejad.app, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by ejad.app and how we use it.If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in ejad.app. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of theFree Privacy Policy Generator.",
  [localizationKeys.contestTitle]: "Consent",
  [localizationKeys.contestBody]:
    "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
  [localizationKeys.informationWeCollectTitle]: "Information we collect",
  [localizationKeys.informationWeCollectBody]:
    "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.",
  [localizationKeys.howWeUseYourInformationTitle]:
    "How we use your information",
  [localizationKeys.howWeUseYourInformationBody]:
    "We use the information we collect in various ways, including to: Provide, operate, and maintain our website,Improve, personalize, and expand our website,Understand and analyze how you use our website,Develop new products, services, features, and functionality,Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes,Send you emails,Find and prevent fraud",
  [localizationKeys.logFilesTitle]: "Log Files",
  [localizationKeys.logFilesBody]:
    "ejad.app follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.",
  [localizationKeys.cookiesAndWebBeaconsTitle]: "Cookies and Web Beacons",
  [localizationKeys.cookiesAndWebBeaconsBody]:
    "Like any other website, ejad.app uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.",
  [localizationKeys.advertisingPartnersPrivacyPoliciesTitle]:
    "Advertising Partners Privacy Policies",
  [localizationKeys.advertisingPartnersPrivacyPoliciesBody]:
    "You may consult this list to find the Privacy Policy for each of the advertising partners of ejad.app.Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on ejad.app, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.Note that ejad.app has no access to or control over these cookies that are used by third-party advertisers.",
  [localizationKeys.thirdPartyPrivacyPoliciesTitle]:
    "Third Party Privacy Policies",
  [localizationKeys.thirdPartyPrivacyPoliciesBody]:
    "ejad.app's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.",
  [localizationKeys.CCPAPrivacyRightsTitle]:
    "CCPA Privacy Rights (Do Not Sell My Personal Information)",
  [localizationKeys.CCPAPrivacyRightsBody]:
    "Under the CCPA, among other rights, California consumers have the right to:Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.Request that a business delete any personal data about the consumer that a business has collected.Request that a business that sells a consumer's personal data, not sell the consumer's personal data.If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.",
  [localizationKeys.GDPRDataProtectionRightsTitle]:
    "GDPR Data Protection Rights",
  [localizationKeys.GDPRDataProtectionRightsBody]:
    "We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.The right to erasure – You have the right to request that we erase your personal data, under certain conditions.The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.",
  [localizationKeys.ChildrensInformationTitle]: "Children's Information",
  [localizationKeys.ChildrensInformationBody]:
    "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.ejad.app does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.",
  [localizationKeys.phoneNumberPlaceHolder]: "xxx xxx xx",
  [localizationKeys.hideSchoolIdentity]: "Hide School Identity",
  [localizationKeys.agreeToAll]: "agree to all",
  [localizationKeys.termsAndConditions]: "terms and conditions",
  [localizationKeys.noDraftedJobs]: "There are no drafted jobs.",
  [localizationKeys.noClosedJobs]: "There are no closed jobs.",
  [localizationKeys.notificationsNotFound]:
    "There are no notifications available",
  [localizationKeys.now]: "now",
  [localizationKeys.from]: "from",
  [localizationKeys.viewAll]: "View All",
  [localizationKeys.ago]: "ago",
  [localizationKeys.minute]: "minute",
  [localizationKeys.hour]: "hour",
  [localizationKeys.notifications]: "Notifications",
  [localizationKeys.notification]: "Notification",
  [localizationKeys.body]: "Body",
  [localizationKeys.hideIdentityTitle]: "Hide Identity",
  [localizationKeys.hideIdentityBody]:
    "On activating the feature to hide school's identity, the data [school name - address - logo] will not appear to the user",
  [localizationKeys.hiddenIdentity]: "Hidden Identity",
  [localizationKeys.hiddenIdentityInfo]:
    "This post does not show any information about the school's identity",
  [localizationKeys.instructor]: "Instructor",
  [localizationKeys.instructors]: "Instructors",
  [localizationKeys.instructorsPlural]: "Instructors",
  [localizationKeys.englishLanguageLevel]: "English language level",
  [localizationKeys.studies]: "Studies",
  [localizationKeys.educationalQualification]: "Educational qualification",
  [localizationKeys.specialization]: "Specialization",
  [localizationKeys.graduationYear]: "Graduation year",
  [localizationKeys.trainingCourses]: "Training courses",
  [localizationKeys.pastTrainingCourses]: "Past training courses",
  [localizationKeys.courseName]: "Course name",
  [localizationKeys.theOrganization]: "The organization",
  [localizationKeys.courseDuration]: "Course duration",
  [localizationKeys.certificate]: "Certificate",
  [localizationKeys.pastExperience]: "Past experience",
  [localizationKeys.schoolName]: "School name",
  [localizationKeys.yearsOfExperience]: "Years of experience",
  [localizationKeys.filterBySubject]: "Filter by subject",
  [localizationKeys.instructorName]: "Instructor Name",
  [localizationKeys.nationality]: "Nationality",
  [localizationKeys.remainLimitPopup]:
    "You have {X} profiles left to view, the cost of viewing a profile later is {NUM} SAR",
  [localizationKeys.payToView]:
    "Pay {X} SAR to view the full instructor profile",
  [localizationKeys.gotEjadCertificate]: "Finished one of Ejad courses",
  [localizationKeys.wantToBuy]: "Want to buy that instructor profile?",
  [localizationKeys.notPermittedWithoutBuy]:
    "Instructor details cannot be viewed without buying it",
  [localizationKeys.searchBySubject]: "Search by subject",
  [localizationKeys.reset]: "reset",
  [localizationKeys.filter]: "filter",
  [localizationKeys.mobileNumber]: "mobile number",
  [localizationKeys.specializationDegree]: "specialization degree",
  [localizationKeys.specialization]: "specialization",
  [localizationKeys.courseOrganization]: "Course Organization",
  [localizationKeys.jobTitle]: "Job Title",
  [localizationKeys.experienceYears]: "Experience Years",
  [localizationKeys.BreakingLimits]: "Sports program",
  [localizationKeys.BreakingLimitsAlert]:
    "to download the program's materials, please go to Settings first to complete your subscription information.",
  [localizationKeys.toSubscribtion]:
    "To subscribe, please follow the following:",
  [localizationKeys.subscribtionRoleOne]:
    "1- Download lessons from the platform.",
  [localizationKeys.subscribtionRoleTwo]:
    "2- Attend the introductory course for the program. To register, click here.",
  [localizationKeys.subscribtionRoleThree]:
    "3- Fill out the preliminary questionnaire. Click for Arabic, for English.",
  [localizationKeys.subscribtionRoleFour]: "4- Providing lessons to classes.",
  [localizationKeys.subscribtionRoleFive]:
    "5- Upload photos of school sports events to the Ejad platform.",
  [localizationKeys.subscribtionRoleSix]:
    "6- Fill out the post-questionnaire. Click for Arabic, for English.",
  [localizationKeys.DownloadMaterialByEnglish]: "Download Material by",
  [localizationKeys.DownloadMaterialByArabic]: "Download Material by",
  [localizationKeys.go]: "go",
  [localizationKeys.allSchoolsProfiles]: "schools participating in the program",
  [localizationKeys.programInfo]: "Sports program info",
  [localizationKeys.add_edit_programInfo]: "( Add / edit ) program info",
  [localizationKeys.teachersName]: "teacher's name",
  [localizationKeys.teachersNameAr]: "teacher's name in Arabic",
  [localizationKeys.teachersNameEn]: "teacher's name in English",
  [localizationKeys.teachersPhoneNumber]: "teacher's phone number",
  [localizationKeys.numberOfStudents]: "number of students (enrolled)",
  [localizationKeys.numberOfBoys]: "number of boys",
  [localizationKeys.numberOfGirls]: "number of girls",
  [localizationKeys.educationalStage]: "educational stage",
  [localizationKeys.numberOfServings]:
    "Number of phusical education classes(per week for grade level)",
  [localizationKeys.save]: "save",
  [localizationKeys.uploadImage]: "upload image",
  [localizationKeys.deleteImage]: "delete image",
  [localizationKeys.deleteImageTitle]: "Delete Image",
  [localizationKeys.deleteImageText]:
    "Are you sure you want to delete this image?",
  [localizationKeys.deleteImageConfirm]: "Confirm",
  [localizationKeys.deleteImageCancel]: "Cancel",
  [localizationKeys.limitUploadImageTitle]: "Limit Upload Image",
  [localizationKeys.limitUploadImageText]:
    "You can upload a maximum of 3 images",
  [localizationKeys.limitUploadImageConfirm]: "ok",
  [localizationKeys.viewAllAlbum]: "view all album",
  [localizationKeys.noImagesUploaded]: "No images uploaded",
  [localizationKeys.uploadImageSuccessTitle]: "Upload Image Success",
  [localizationKeys.studentGender]: "student gender",
  [localizationKeys.educationProgramUpdated]: "education program updated",
  [localizationKeys.deleteImage]: "delete image",
  [localizationKeys.deletedImageText]:
    "The image has been deleted successfully",
};

export default en;
