import {
  Button,
  Dropdown,
  Form,
  FormField,
  Loader,
  Modal,
} from "semantic-ui-react";
import { FaPlus } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useEffect } from "react";
import content from "../../../localization/content";
import { useLanguage } from "../../../lib/language-context";
import localizationKeys from "../../../localization/localization-keys";
import { Formik } from "formik";
export default function AddAndEditInfo({
  isLoading,
  inputs,
  setInputs,
  data,
  setData,
  formik,
  addRowField,
  removeRowField,
  open,
  setOpen,
}) {
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const educationalStageOptions = [
    { key: "kindergarten", value: "رياض اطفال", text: "رياض اطفال" },
    { key: "primary", value: "الابتدائية", text: "الابتدائية" },
    { key: "intermediate", value: "متوسطة", text: "متوسطة" },
    { key: "secondary", value: "الثانوية", text: "الثانوية" },
  ];

  useEffect(() => {
    if (data) {
      formik.setValues(data);
    }
  }, [data]);

  return (
    <div>
      <Button primary onClick={() => setOpen(true)}>
        {selectedContent[localizationKeys.add_edit_programInfo]}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Content>
          <section className="my-6 rounded-lg border border-gray-200 p-4">
            <Formik initialValues={{ ...formik.initialValues }}>
              <Form onSubmit={formik.handleSubmit}>
                {formik.values?.teachersInfo?.map((teacher, index) => (
                  <div key={index} className="mb-4 flex items-center gap-2">
                    <div className="flex flex-1 flex-wrap justify-between">
                      <div className="flex w-full flex-col gap-2 px-2 lg:w-1/2 lg:flex-row">
                        <FormField>
                          <label htmlFor={`teachersInfo[${index}].name.ar`}>
                            {selectedContent[localizationKeys.teachersNameAr]}:
                          </label>
                          <input
                            name={`teachersInfo[${index}].name.ar`}
                            id={`teachersInfo[${index}].name.ar`}
                            className="w-full rounded-lg"
                            type="text"
                            value={teacher?.name.ar}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={data?.teachersInfo?.[index]?.name?.ar}
                          />
                          {formik.touched?.teachersInfo?.[index]?.name?.ar &&
                            formik.errors?.teachersInfo?.[index]?.name?.ar && (
                              <div className="text-red-500">
                                {formik.errors?.teachersInfo?.[index]?.name?.ar}
                              </div>
                            )}
                        </FormField>
                        <FormField>
                          <label htmlFor={`teachersInfo[${index}].name.en`}>
                            {selectedContent[localizationKeys.teachersNameEn]}:
                          </label>
                          <input
                            name={`teachersInfo[${index}].name.en`}
                            id={`teachersInfo[${index}].name.en`}
                            className="w-full rounded-lg"
                            type="text"
                            value={teacher?.name.en}
                            onBlur={formik.handleBlur}
                            placeholder={data?.teachersInfo?.[index]?.name?.en}
                            onChange={formik.handleChange}
                          />
                          {formik.touched?.teachersInfo?.[index]?.name?.en &&
                            formik.errors?.teachersInfo?.[index]?.name?.en && (
                              <div className="text-red-500">
                                {formik.errors?.teachersInfo?.[index]?.name?.en}
                              </div>
                            )}
                        </FormField>
                      </div>
                      <div className="mt-6 w-full px-2 lg:mt-0 lg:w-1/2">
                        <FormField>
                          <label htmlFor={`teachersInfo[${index}].phone`}>
                            {
                              selectedContent[
                                localizationKeys.teachersPhoneNumber
                              ]
                            }
                            :
                          </label>
                          <input
                            name={`teachersInfo[${index}].phone`}
                            id={`teachersInfo[${index}].phone`}
                            type="tel"
                            value={teacher?.phone}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={data?.teachersInfo?.[index]?.phone}
                            className="w-full rounded-lg"
                          />
                          {formik.touched?.teachersInfo?.[index]?.phone &&
                            formik.errors?.teachersInfo?.[index]?.phone && (
                              <div className="text-red-500">
                                {formik.errors?.teachersInfo?.[index]?.phone}
                              </div>
                            )}
                        </FormField>
                      </div>
                    </div>
                    <div className="mb-4">
                      <Button
                        type="button"
                        onClick={addRowField}
                        className="group relative top-1/2 translate-y-1/2 rounded-lg  border border-primary-900 p-2 hover:bg-primary-900"
                      >
                        <FaPlus className="text-primary-900 group-hover:text-white" />
                      </Button>
                    </div>
                    <div className="mb-4 px-2">
                      <Button
                        type="button"
                        onClick={() => removeRowField(index)}
                        className="group relative top-1/2 translate-y-1/2 cursor-pointer rounded-lg border border-gray-200 bg-red-400 p-2 hover:bg-red-500"
                      >
                        <MdDeleteForever className="text-white" />
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="my-4 w-full px-2">
                  <FormField>
                    <label htmlFor="educationStage">
                      {selectedContent[localizationKeys.educationalStage]} :
                    </label>
                    <Dropdown
                      placeholder="choose Educational Stage"
                      fluid
                      multiple
                      selection
                      options={educationalStageOptions}
                      name="educationStage"
                      value={formik.values.educationStage}
                      onChange={(e, { value }) => {
                        formik.setFieldValue("educationStage", value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </FormField>
                </div>
                <div className="my-4 w-fit px-2">
                  <FormField>
                    <label htmlFor="NumberOfServings">
                      {" "}
                      {selectedContent[localizationKeys.numberOfServings]} :
                    </label>
                    <input
                      name="NumberOfServings"
                      id="NumberOfServings"
                      className="w-fit rounded-lg"
                      type="text"
                      value={formik.values.NumberOfServings}
                      placeholder={data?.NumberOfServings}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormField>
                </div>
                <div className="my-4 flex flex-col flex-wrap lg:flex-row lg:items-center lg:justify-between">
                  <div className="my-2 w-fit px-2 lg:my-0">
                    <FormField>
                      <label htmlFor="numberOfStudents">
                        {selectedContent[localizationKeys.numberOfStudents]}:
                      </label>
                      <input
                        name="numberOfStudents"
                        id="numberOfStudents"
                        className="w-fit rounded-lg"
                        type="text"
                        value={formik.values.numberOfStudents}
                        placeholder={data?.numberOfStudents}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </FormField>
                  </div>
                  <div className="my-2 w-fit px-2 lg:my-0">
                    <FormField>
                      <label htmlFor="boysStudents">
                        {selectedContent[localizationKeys.numberOfBoys]}:
                      </label>
                      <input
                        name="boysStudents"
                        id="boysStudents"
                        className="w-fit rounded-lg"
                        type="text"
                        value={formik.values.boysStudents}
                        placeholder={data?.boysStudents}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </FormField>
                  </div>
                  <div className="my-2 w-fit px-2 lg:my-0">
                    <FormField>
                      <label htmlFor="girlsStudents">
                        {selectedContent[localizationKeys.numberOfGirls]}:
                      </label>
                      <input
                        name="girlsStudents"
                        id="girlsStudents"
                        className="w-fit rounded-lg"
                        type="text"
                        value={formik.values.girlsStudents}
                        placeholder={data?.girlsStudents}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="text-center">
                  <Button type="submit" primary className="relative h-12 w-40">
                    {selectedContent[localizationKeys.save]}
                    <span className="absolute top-1/2 -translate-y-1/2 px-4">
                      <Loader
                        className={`${isLoading ? "visible" : "hidden"}`}
                        size="tiny"
                        active={isLoading}
                      />
                    </span>
                  </Button>
                </div>
              </Form>
            </Formik>
          </section>
        </Modal.Content>
      </Modal>
    </div>
  );
}
